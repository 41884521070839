import { Component } from 'react';
import Routing from 'routing';
import { Button, ButtonVariant, Alert, AlertVariant } from '@estimateone/frontend-components';
import E1Request from '../../../../js/classes/E1Request';
import NewUserRedirect from '../NewUserRedirect';
import LoadingSpinnerFloating from '@shared/LoadingSpinner/LoadingSpinnerFloating';
import styles from './styles.scss';

enum InviteAction {
  Accept = 'accept',
  Decline = 'decline',
}

const actionInvite = async (action: InviteAction, token: string) =>
  // eslint-disable-next-line camelcase
  new E1Request<{ success: true; data?: { user_token: string } }>(
    Routing.generate(`api_account_invite_${action}`, { token }),
    'POST',
    {},
    true,
  ).submit();

type Props = {
  inviteToken: string;
  accountName: string;
  userName: string;
};

class AccountInviteConfirmForm extends Component<
  Props,
  {
    accepting: boolean;
    declining: boolean;
    success: boolean | null;
    responded: boolean;
    userToken: string | null;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accepting: false,
      declining: false,
      success: null,
      responded: false,
      userToken: null,
    };
  }

  handleAccept = async () => {
    const { accepting } = this.state;

    if (accepting) return;

    this.setState({
      accepting: true,
      success: null,
    });
    const { inviteToken } = this.props;

    try {
      const inviteAcceptResponse = await actionInvite(InviteAction.Accept, inviteToken);
      const userToken = inviteAcceptResponse.data?.user_token || null;
      this.setState({
        responded: true,
        accepting: false,
        success: true,
        userToken,
      });
    } catch (err) {
      this.setState({
        accepting: false,
        success: false,
      });
    }
  };

  handleDecline = async () => {
    const { declining } = this.state;

    if (declining) return;

    this.setState({
      declining: true,
      success: null,
    });
    const { inviteToken } = this.props;

    try {
      await actionInvite(InviteAction.Decline, inviteToken);
      this.setState({
        responded: true,
        declining: false,
        success: true,
      });
    } catch (err) {
      this.setState({
        declining: false,
        success: false,
      });
    }
  };

  render() {
    const { accepting, declining, success, responded, userToken } = this.state;
    const { accountName, userName } = this.props;

    const alert = success ? (
      userToken !== null ? (
        <NewUserRedirect userToken={userToken} />
      ) : (
        <div>
          <p>Thank you for your response.</p>
        </div>
      )
    ) : (
      <Alert variant={AlertVariant.Red} className={styles.alert}>
        <strong>Something went wrong</strong>
      </Alert>
    );

    return (
      <>
        {!responded && (
          <div className={styles.inviteMessage}>
            <p>
              Your colleague <strong>{userName}</strong>, has invited you to join{' '}
              <strong>{accountName}</strong> on E1.
            </p>
            <p>What would you like to do?</p>
          </div>
        )}
        {success !== null && alert}
        {!responded && (
          <div className={styles.buttonGroup}>
            <Button
              variant={ButtonVariant.Primary}
              disabled={accepting}
              onClick={this.handleAccept}
            >
              {accepting ? 'Accepting...' : 'Accept'}
            </Button>
            <Button variant={ButtonVariant.Red} disabled={declining} onClick={this.handleDecline}>
              {declining ? 'Declining...' : 'Decline'}
            </Button>
          </div>
        )}
        {(declining || accepting) && <LoadingSpinnerFloating />}
      </>
    );
  }
}

export default AccountInviteConfirmForm;
