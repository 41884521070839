import { useEffect } from 'react';
import Routing from 'routing';

type NewUserRedirectProps = { userToken: string };

const NewUserRedirect = ({ userToken: u }: NewUserRedirectProps) => {
  useEffect(() => {
    window.location.assign(Routing.generate('app_default_index', { u }));
  });

  return (
    <p>
      {' '}
      <span>
        <strong>Team successfully joined</strong> <br /> <br />
        You will be redirected into this account shortly.
      </span>
    </p>
  );
};

export default NewUserRedirect;
