import 'core-js/stable';
import 'custom-event-polyfill';
import 'isomorphic-unfetch';
import { renderWithParents } from '@ascension/components/render-helper';
import TeamInviteConfirmationPage from '@subbie/pages/TeamInviteConfirmationPage';
import { filterEmptyMissingReports, reportIfMissing } from './util';
import ErrorBoundary from '@shared/ErrorBoundary';

renderWithParents(<ErrorBoundary />)('team-invite-confirm-page')(
  ({ token: inviteToken, accountName, userName }) => {
    if (!inviteToken || !accountName || !userName) {
      const missing = filterEmptyMissingReports([
        reportIfMissing('inviteToken', inviteToken),
        reportIfMissing('accountName', accountName),
        reportIfMissing('userName', userName),
      ]);
      throw new Error(`team-invite-confirm-page: missing ${missing.join(', ')}`);
    }
    return (
      <TeamInviteConfirmationPage
        inviteToken={inviteToken}
        accountName={accountName}
        userName={userName}
      />
    );
  },
);
