import AccountInviteConfirmForm from '../../form/AccountInviteConfirmForm';

type TeamInviteConfirmationPageProps = {
  inviteToken: string;
  accountName: string;
  userName: string;
};

const TeamInviteConfirmationPage = ({
  inviteToken,
  accountName,
  userName,
}: TeamInviteConfirmationPageProps) => (
  <div className="section table-container">
    <div className="row">
      <div className="col-sm-12">
        <h2>Team Invite Confirmation</h2>
      </div>
    </div>
    <AccountInviteConfirmForm
      inviteToken={inviteToken}
      accountName={accountName}
      userName={userName}
    />
  </div>
);

export default TeamInviteConfirmationPage;
