import LoadingSpinner from '.';

const LoadingSpinnerFloating = () => (
  <div
    style={{
      backgroundColor: '#000',
      position: 'fixed',
      zIndex: 999,
      width: '40px',
      height: '40px',
      overflow: 'show',
      margin: 'auto',
      padding: '11px',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: '11px',
    }}
  >
    <LoadingSpinner lightBackground={false} />
  </div>
);

export default LoadingSpinnerFloating;
